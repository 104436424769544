import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Image from '../Image';
import './styles.scss';
import PropTypes from 'prop-types';
import { MEDIA_TYPE } from '../../utils/constants';
import Carousel from '../Carousel';
import pauseIcon from '../../images/icon-pause.svg';
import videoBgImage from '../../images/hmpg-video-placeholder-v2.jpg';
import AzureVideo from '../AzureVideo';
import Video from '../Video';

const Hero = ({ mediaSource, mediaType, guidSrc, textOverlay, className }) => {
  const [loaded, setLoaded] = useState(false);
  const [paused, setPaused] = useState(false);
  const isVideo = mediaType && Object.values(MEDIA_TYPE).includes(mediaType);
  const isMultiImage = Array.isArray(mediaSource);

  /**
   * useEffect used to delay the loading of video so other
   * images can start downloaded before the video downloads.
   * This is needed for iPad, because Safari on iOS 12 will
   * try to load the video and block all other image downloads,
   * so we render the video AFTER load to allow other images to
   * start their download first.
   */
  useEffect(() => {
    setLoaded(true);
  }, []);

  const handlePause = () => {
    if (paused) {
      setPaused(false);
    } else {
      setPaused(true);
    }
  };

  if (isVideo) {
    return (
      <div className={classNames('hero', className)}>
        <div className="hero__skew-shape"></div>
        <div className="hero__video">
          {loaded && (
            <AzureVideo
              videoSrc="https://primaryusproduction-use2.streaming.media.azure.net/94f1776a-5da0-4187-b5fa-948c4d18bca3/nielsen-homepag.ism/manifest%28format%3Dm3u8-aapl%29"
              poster={videoBgImage}
              controls={false}
              loop
              muted
              autoplay
              pause={paused}
            />
            // <Video guidSrc={guidSrc} />
          )}
        </div>
        {textOverlay}
        <div className="hero__video-pause" onClick={handlePause}>
          {paused ? (
            <span className="play-hero-video" />
          ) : (
            <img src={pauseIcon} alt="pauseIcon" />
          )}
        </div>
      </div>
    );
  } else if (isMultiImage) {
    return (
      <div className={classNames('hero', className)}>
        <Carousel
          headingClassOverride={'mb-0'}
          imageContainerClassOverride={'mb-0'}
          slideClassOverride={'mb-0'}
          settings={{ fade: true, className: 'hero__inner-slider-div' }}
          slides={mediaSource}
        />
        {textOverlay}
      </div>
    );
  } else {
    return (
      <div className={classNames('hero', className)}>
        <Image filename={mediaSource} className="hero__img" />
        {textOverlay}
      </div>
    );
  }
};

Hero.defaultProps = {
  textOverlay: null,
  className: null,
};

Hero.propTypes = {
  /** A path to video or a filename for an image (using the Image Component)
   * or an array of objects for multi image slider
   */
  mediaSource: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  ]).isRequired,
  /** The type used for the video */
  mediaType: PropTypes.oneOf(Object.values(MEDIA_TYPE)),
  /** Text overlay for the image/video. The hero-text-overlay-container-defaults mixin
   * contains the default CSS for a left side text overlay.
   */
  textOverlay: PropTypes.object,
  /** A custom class name */
  classNames: PropTypes.string,
};

export default Hero;
