import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

let videoCounter = 1;
/**
 * A video component that provides more control via the Azure Media Player API.
 * This allows special use cases such as a looping hero image.
 */
const AzureVideo = ({
  videoSrc,
  autoplay,
  controls,
  poster,
  loop,
  pause,
  muted,
}) => {
  const [videoReady, setVideoReady] = useState(false);
  const idValue = useRef(`vid-${videoCounter++}`);
  const wrapperRef = useRef(null);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const posterOverlayRef = useRef(null);

  // Initialize the video
  useEffect(() => {
    // Create the video element
    const videoElement = document.createElement('video');
    videoElement.id = idValue.current;
    videoElement.style =
      'position: absolute; top: 0; left: 0; height: 100%; width: 100%';
    videoElement.classList.add('azuremediaplayer');
    videoElement.classList.add('amp-default-skin');
    videoElement.setAttribute('playsinline', true);
    if (controls) {
      videoElement.setAttribute('controls', true);
    }
    if (autoplay) {
      videoElement.setAttribute('autoplay', true);
    } else {
      // If not autoplay, let's at least preload metadata
      videoElement.setAttribute('preload', 'metadata');
    }
    if (muted) {
      videoElement.setAttribute('muted', true);
    }
    if (loop) {
      videoElement.setAttribute('loop', true);
    }
    if (poster) {
      videoElement.setAttribute('poster', poster);
    }
    videoRef.current = videoElement;
    // Create the overlay placeholderPoster
    const overlayPoster = document.createElement('img');
    overlayPoster.src = poster;
    overlayPoster.style =
      'position: absolute;top: 0;left: 0;width: 100%; height: 100%; z-index: 2;opacity: 1;transition: opacity 0.5s';
    overlayPoster.style.display = 'block';
    posterOverlayRef.current = overlayPoster;
    // Make the video element a child of the wrapper
    wrapperRef.current.appendChild(videoElement);
    wrapperRef.current.appendChild(overlayPoster);
    // Init player
    // We use set timeout to let the poster placeholder render now.
    setTimeout(() => {
      playerRef.current = window.amp(
        idValue.current,
        {
          /* Options */
          techOrder: ['azureHtml5JS', 'html5FairPlayHLS', 'html5'],
          nativeControlsForTouch: false,
          poster: poster,
          hotKeys: {
            enableVolumeScroll: false,
          },
        },
        function () {
          setVideoReady(true);
          // add an event listener
          // this.addEventListener('ended', function () {
          //   console.log('Finished!');
          // });
        }
      );
      playerRef.current.src([
        {
          src: videoSrc,
          type: 'application/vnd.ms-sstr+xml',
        },
      ]);
    }, 0);
    // Configure cleanup when component dismounts.
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [autoplay, controls, loop, muted, poster, videoSrc]);

  // Hide the poster after the video is ready
  useEffect(() => {
    if (videoReady && posterOverlayRef.current) {
      setTimeout(() => {
        posterOverlayRef.current.style.opacity = '0';
      }, 300);
    }
  }, [videoReady]);

  // Handle pause
  useEffect(() => {
    if (videoReady && playerRef.current) {
      if (pause && !playerRef.current.paused()) {
        playerRef.current.pause();
      }
      if (!pause && playerRef.current.paused()) {
        // The 'paused' method returns a false positive when the video hasn't even started yet
        // To prevent this error: "TypeError: Cannot read properties of null (reading 'getBufferedAheadOfCurrentTimeInSec')"
        // We check the 'TimeRanges' object returned by the 'buffered method.
        const buffered = playerRef.current.buffered();
        if (buffered && buffered.length && buffered.end(0) > 0) {
          playerRef.current.play();
        }
      }
    }
  }, [pause, videoReady]);

  return (
    <div
      ref={wrapperRef}
      style={{
        position: 'relative',
        height: '0',
        paddingTop: '56.25%',
        zIndex: '0',
      }}
    ></div>
  );
};
AzureVideo.defaultProps = {
  autoplay: false,
  controls: true,
  poster: null,
  pause: false,
  muted: false,
  loop: false,
};
AzureVideo.propTypes = {
  autoplay: PropTypes.bool,
  controls: PropTypes.bool,
  poster: PropTypes.string,
  pause: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
};
export default AzureVideo;
